//申请列表
const talentApplyList = `/gateway/hc-neighbour/talent/getTalentAuditInfoList`;
//达人审核
const talentAudit = `/gateway/hc-neighbour/talent/examineTalentAuditInfoRecord`;
//达人类型
const talentTypeList = `/gateway/hc-neighbour/talent/getInitInfo`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;

export { talentApplyList, talentAudit, getCommunityListUrl, talentTypeList };
