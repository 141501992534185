<template>
  <div class="addActivity">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <!--        <v-button text="新增" @click="toAdd"></v-button>-->
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <area-select
          @changeModel="changeModel"
          specifyCollectionTypes="1"
          :disabled="true"
        />
        <!-- <community-select v-model="searchParam.communityId" label="选择园区" /> -->
        <v-select
          clearable
          :options="talentTypeOptions"
          v-model="searchParam.talentType"
          @change="$refs.list.search()"
          label="达人类型"
        />
        <v-input label="发布人姓名" v-model="searchParam.userName" />
        <v-select
          clearable
          :options="auditStatusOptionsAll"
          v-model="searchParam.auditStatus"
          @change="$refs.list.search()"
          label="审核状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="审核"
          type="text"
          v-if="scope.row.auditStatus == 0"
          @click="toAudit(scope.row)"
        />
        <v-button
          text="详情"
          type="text"
          v-if="scope.row.auditStatus != 0"
          @click="toView(scope.row)"
        />
      </template>
    </v-list>

    <el-dialog
      title="审核"
      :visible.sync="auditDialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px">
        <el-form-item label="申请人">
          <v-input v-model="tempObj.userName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="达人类型">
          <v-input v-model="tempObj.talentType" :width="400" disabled />
        </el-form-item>
        <el-form-item label="申请时间">
          <v-input v-model="tempObj.createTime" :width="400" disabled />
        </el-form-item>
        <el-form-item label="申请信息">
          <v-input v-model="tempObj.talentContent" :width="400" disabled />
        </el-form-item>
        <el-form-item label="图片">
          <v-upload :imgUrls.sync="tempObj.talentPic" disabled />
        </el-form-item>
        <el-form-item label="审核结果">
          <v-select
            :disabled="!tempObj.isAudit"
            :options="auditStatusOptions"
            v-model="tempObj.auditStatus"
          />
        </el-form-item>
        <el-form-item label="奖励积分" v-if="tempObj.auditStatus == '1'">
          <v-input
            v-model="tempObj.bonusPoints"
            type="number"
            :disabled="tempObj.auditStatusOrg != '0'"
          />
        </el-form-item>
        <el-form-item label="备注信息">
          <v-input
            v-model="tempObj.auditRemarks"
            :width="400"
            :maxlength="100"
            :disabled="!tempObj.isAudit"
          />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer dialogBottomBtn"
        v-if="tempObj.isAudit"
      >
        <v-button
          size="medium"
          @click="auditDialogFormVisible = false"
          text="取消"
        ></v-button>
        <v-button
          size="medium"
          type="primary"
          @click="doAudit"
          text="确定"
        ></v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { talentApplyList, talentAudit, talentTypeList } from "./api.js";
import { createImgVNode } from "@/utils/utils.js";

export default {
  name: "activityAuditList",
  data() {
    return {
      auditDialogFormVisible: false,
      talentTypeOptions: [],
      tempObj: {
        id: null,
        communityName: null,
        userName: null,
        createTime: null,
        talentType: null,
        talentContent: null,
        talentPic: null,
        bonusPoints: null,
        auditUser: null,
        auditStatus: null,
        auditRemarks: null,
        auditStatusOrg: null,
      },
      auditStatusOptions: [
        { value: "1", label: "审核通过" },
        { value: "2", label: "审核驳回" },
      ],
      auditStatusOptionsAll: [
        { value: "0", label: "待审核" },
        { value: "1", label: "审核通过" },
        { value: "2", label: "审核驳回" },
      ],

      searchParam: {
        spaceIds: null,
        userName: null,
        auditStatus: null,
        talentType: null,
      },
      tableUrl: talentApplyList,
      headers: [
        {
          prop: "communityName",
          label: "园区名称",
        },
        {
          prop: "userMobileNum",
          label: "申请人手机号",
        },
        {
          prop: "userName",
          label: "发布用户",
        },
        {
          prop: "createTime",
          label: "发布时间",
        },
        {
          prop: "talentType",
          label: "达人类型",
        },
        {
          prop: "talentContent",
          label: "申请理由",
        },
        {
          prop: "talentPic",
          label: "图片",
          formatter: (row, prop) => {
            row.talentPicSingle = (row.talentPic || "").split(",")[0];
            return createImgVNode(this, row, "talentPicSingle");
          },
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter: (row, prop) => {
            for (let i = 0; i < this.auditStatusOptionsAll.length; i++) {
              if (
                row.auditStatus === Number(this.auditStatusOptionsAll[i].value)
              ) {
                return this.auditStatusOptionsAll[i].label;
              }
            }
          },
        },
        {
          prop: "auditTime",
          label: "审核时间",
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.getTalentTypeList();
  },
  computed: {},
  methods: {
    changeModel(value) {
      this.searchParam.spaceIds = value.spaceId;
    },
    toAudit(data) {
      this.auditDialogFormVisible = true;
      this.tempObj = {
        isAudit: true,
        id: data.id,
        communityName: data.communityName,
        userName: data.userName,
        createTime: data.createTime,
        talentType: data.talentType,
        talentContent: data.talentContent,
        talentPic: data.talentPic,
        bonusPoints: data.bonusPoints,
        auditStatusOrg: data.auditStatus,
        auditStatus: null,
        auditRemarks: null,
        auditUser: 1428626703104688129,
      };
    },

    toView(data) {
      this.auditDialogFormVisible = true;
      this.tempObj = {
        isAudit: false,
        id: data.id,
        communityName: data.communityName,
        userName: data.userName,
        createTime: data.createTime,
        talentType: data.talentType,
        talentContent: data.talentContent,
        talentPic: data.talentPic,
        auditStatus: data.auditStatus.toString(),
        auditRemarks: data.auditRemarks,
        auditUser: 1428626703104688129,
      };
    },

    doAudit() {
      if (!this.tempObj.auditStatus) {
        this.$message.error("请选择审核结果！");
        return;
      }
      if (this.tempObj.bonusPoints && this.tempObj.bonusPoints < 0) {
        this.$message.error("奖励积分不能小于0！");
        return;
      }

      if (
        this.tempObj.auditStatus == 2 &&
        (!this.tempObj.auditRemarks ||
          this.tempObj.auditRemarks.trim().length == 0)
      ) {
        this.$message.error('请在"备注信息"中填写驳回理由！');
        return;
      }

      this.$axios
        .post(`${talentAudit}`, {
          id: this.tempObj.id,
          auditStatus: this.tempObj.auditStatus,
          auditRemarks: this.tempObj.auditRemarks,
          bonusPoints: this.tempObj.bonusPoints,
          auditUser: this.tempObj.auditUser,
        })
        .then((res) => {
          if (res.code === 200) {
            this.auditDialogFormVisible = false;
            this.$message.success("操作成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    getTalentTypeList() {
      let params = {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      this.talentTypeOptions = [];
      this.$axios.get(`${talentTypeList}`, { params: params }).then((res) => {
        if (res.code === 200 && res.data.talentTypeList) {
          res.data.talentTypeList.forEach((item) => {
            item.label = item.dictValue;
            item.value = item.dictValue;
            this.talentTypeOptions.push(item);
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
}
</style>
